<template>
    <div>
        <div class="mt-5 col-span-2 bg-white-pure l-box-shadow rounded px-4 py-3">
            <EpisodeHeader
                :episodes="episodes"
                :episode="computedEpisode"
                :tab="'ACTION_PLAN'"
                @episode-changed="episodeChanged"
            />
        </div>
        <div class="mt-5 col-span-2 bg-white-pure l-box-shadow rounded px-4 py-3">
            <div class="flex flex-row justify-between pb-4 items-center">
                <h4 class="font-display font-semibold text-left text-lg">Goals</h4>
                <v-btn
                    depressed
                    class="mt-2 v-btn--flat inline"
                    color="primary"
                    @click="downloadPdf"
                >
                    <fa-icon icon="download" class="mr-1 group-hover:text-primary-light" /> Download PDF
                </v-btn>
            </div>
            <div class="flex items-center mb-4">
                <div class="mr-2">
                    <fa-icon icon="filter" class="text-sm text-black" />
                </div>
                <v-checkbox
                    hide-details
                    class="mt-0 mr-4"
                    :ripple="false"
                    v-model="showCurrent"
                    label="Current Goals"
                    @change="clearFilters"
                ></v-checkbox>
            </div>

            <ActionPlanTable
                :tableData="actionPlans"
                :users="users"
                :episode="computedEpisode"
                :isAddable="$can('add', 'action_plan')"
                :isEditable="$can('edit', 'action_plan')"
                @table-data-change="tableDataUpdated"
                @action-plan-saved="actionPlansUpdated"
            />
        </div>
    </div>
</template>

<script>
import camelcaseKeys from 'camelcase-keys'
import axios from '@/utils/axios'
import GET_EPISODES_FOR_CLIENT from '@/graphql/queries/getEpisodesForClient.gql'
import { mapState } from 'vuex'
import EpisodeHeader from '@/components/episodes/partials/EpisodeHeader'
import ActionPlanTable from '@/components/action-plan/partials/ActionPlanTable'
import GET_ACTION_PLANS_FOR_EPISODE from '@/graphql/queries/getActionPlansForEpisode.gql'
import FileMethods from '@/components/shared/mixins/fileMethods'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import GET_USERS from '@/graphql/queries/getUsers.gql'

export default {
    name: 'ActionPlan',
    components: { ActionPlanTable, EpisodeHeader },
    mixins: [ FileMethods, MessageDialog ],
    apollo: {
        episodes: {
            query: GET_EPISODES_FOR_CLIENT,
            variables() {
                return {
                    clientId: Number(this.clientId)
                }
            },
            skip() {
                return this.clientId === null
            },
            update: ({ episodes }) => {
                const openEpisodes = episodes.filter(e => e && e.status === 'OPEN')
                const otherEpisodes = episodes.filter(e => e && e.status !== 'OPEN')
                return camelcaseKeys([...openEpisodes, ...otherEpisodes], {deep: true})
            },
        },
        actionPlans: {
            query: GET_ACTION_PLANS_FOR_EPISODE,
            variables() {
                const variables = {episodeId: Number(this.currentEpisode.episodeId)}
                if (this.showCurrent) {
                    variables.relevantCompare = {_eq: this.showCurrent}
                }
                return variables
            },
            skip() {
                return this.currentEpisode == null || this.currentEpisode.episodeId == null
            },
            result({ data: { actionPlans } }) {
                let convertedActionPlans = camelcaseKeys(actionPlans, {deep: true})
                this.actionPlans = [...convertedActionPlans]
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            }
        },
        users: {
            query: GET_USERS,
            update: ({ users }) => {
                return camelcaseKeys(users, {deep: true})
            },
        }
    },
    data() {
        return {
            clientId: this.$route.params.clientId,
            episodes: [],
            actionPlans: null,
            users: [],
            defaultActionPlan: {
                order: null,
                goal: null,
                action: null,
                commenced: null,
                completed: null,
                rating: null,
                timeFrame: null
            },
            episode: {},
            showCurrent: true
        }
    },
    mounted() {
        this.$store.commit('SET_CLIENT_ID', this.clientId)
        this.$root.$on('create-action-plan', this.createActionPlan)
    },
    methods: {
        async episodeChanged(episode) {
            this.$store.commit('SET_CURRENT_EPISODE', episode)
            this.episode = {...episode}
            await this.actionPlansUpdated()
        },
        createActionPlan(action_plan) {
            console.log('create action plan',action_plan)
            const actionPlan = {...this.defaultActionPlan}
            this.actionPlans.push({
                ...actionPlan
            })
        },
        async clearFilters() {
            // if none of the filters has a value, set to null since that's the only way to get every case note
            if (!this.showCurrent) this.showCurrent = null
            await this.actionPlansUpdated()
        },
        tableDataUpdated(actionPlans) {
            this.actionPlans = actionPlans
        },
        async actionPlansUpdated() {
            await this.$apollo.queries.actionPlans.refetch()
        },
        downloadPdf() {
            axios({
                method: 'GET',
                url: `/ted2_data.episode/${this.computedEpisode.episodeId}/generate_action_plan_pdf/`,
                responseType: 'blob'
            }).then((resp) => {
                const fileUrl = URL.createObjectURL(resp.data)
                const fileLink = document.createElement('a')
                const contentDisposition = resp.headers['content-disposition']
                const defaultFileName = `${this.computedEpisode.episodeId}_stuart_action_plan.pdf`
                let filename = ''
                if (contentDisposition) {
                    // the filename should be the second argument here, if it's not
                    try {
                        filename = contentDisposition ? contentDisposition.split('=')[1] : defaultFileName || ''
                    } catch (e) {
                        console.log('did not return the filename in the content-disposition header')
                        filename = defaultFileName || ''
                    }
                } else {
                    filename = defaultFileName || ''
                }
                fileLink.href = fileUrl
                fileLink.setAttribute('target', '_blank')
                fileLink.setAttribute('download', filename)
                document.body.appendChild(fileLink)
                fileLink.click()
                this.type = 'success'
                this.message = 'Successfully downloaded document'
            })
            .catch((err) => {
                console.log('document download error', err)
                this.type = 'error'
                this.message = 'Sorry, your file could not be downloaded at this time, please contact your system administrator'
            })
            .finally(() => {
                if (!this.showMessage) this.$emit('show-message', {message: this.message, type: this.type})
                else  this.showMessage({duration: 5000})
            })
        },
    },
    computed: {
        ...mapState({
            currentEpisode: state => state.app.currentEpisode
        }),
        computedEpisode() {
            return this.currentEpisode || this.episode
        },
    },
    watch: {
        '$route.path': function(val, oldVal){
            this.$apollo.queries.client.refetch()
        }
    }
}
</script>
